<template>
	<div id="admin-form">
		<ValidationObserver ref="form" v-slot="{ handleSubmit }">
			<form @submit.prevent="handleSubmit(applyForm)">
				<div class="admin-form__header mb-3">
					<h5 v-if="!isUpdate">{{ $t("AdminForm.CreateAdmin") }}</h5>

					<template v-else>
						<h5>{{
							$t("AdminForm.UpdateAdmin", {
								name: get(recentAdmin, "fullName"),
							})
						}}</h5>
						<CButton
							v-bind="{ variant: 'outline' }"
							color="info"
							@click="togglePasswordModal(true)"
							>{{ $t("AdminForm.CreateChangePassword") }}</CButton
						>
					</template>
				</div>

				<div class="admin-form__container">
					<!-- Admin's status -->
					<div class="form-group required">
						<label for="admin-status" class="form-group__label col-form-label">{{
							$t("AdminForm.Status")
						}}</label>
						<div class="form-group__input"
							><ValidationProvider
								ref="status"
								v-slot="{ classes, errors }"
								name="admin_status"
								rules="required"
							>
								<div :class="[classes]">
									<multiselect
										id="admin-status"
										v-model.trim="adminForm.status"
										:custom-label="labelStatus"
										:options="status"
										:searchable="false"
										:show-labels="false"
										:allow-empty="false"
										:disabled="isCurrentUser"
										@input="selectStatus"
										@close="checkValidate('status')"
									>
										<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
										<template #noResult>{{ $t("DropDown.NoResult") }}</template>
									</multiselect>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Admin's name -->
					<div class="form-group required">
						<label for="admin-name" class="form-group__label col-form-label">{{
							$t("AdminForm.Name")
						}}</label>
						<div class="form-group__input"
							><ValidationProvider v-slot="{ classes, errors }" name="admin_name" rules="required">
								<div :class="[classes]">
									<input
										id="admin-name"
										v-model.trim="adminForm.fullName"
										type="text"
										class="form-control"
										:maxlength="maxLength"
										@keydown="checkValidName($event)"
									/>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Admin's email -->
					<div class="form-group required">
						<label for="admin-email" class="form-group__label col-form-label">{{
							$t("AdminForm.EmailLogin")
						}}</label>
						<div class="form-group__input"
							><ValidationProvider
								v-slot="{ classes, errors }"
								name="admin_email"
								:rules="{
									required: !isUpdate,
									email: true,
									isEmail: true,
								}"
							>
								<div :class="[classes]">
									<!-- Create View -->
									<input
										v-if="!isUpdate"
										id="admin-email"
										v-model.trim="adminForm.email"
										type="text"
										class="form-control"
										:maxlength="maxLength"
									/>

									<!-- Update View -->
									<div v-else id="admin-email" class="col-form-label">{{ adminForm.email }}</div>
									<div v-if="isUpdate || errors[0]" class="error-mess">{{ errors[0] }}</div>
									<span v-if="!isUpdate">{{ $t("AdminForm.SendEmailPWD") }}</span>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Admin's Clinic -->
					<div class="form-group required">
						<label for="admin-clinic" class="form-group__label col-form-label">{{
							$t("AdminForm.PKManagement")
						}}</label>
						<div class="form-group__input"
							><ValidationProvider
								ref="clinic"
								v-slot="{ classes, errors }"
								name="admin_clinic"
								:rules="{ required: !isSelectedSystemAdmin }"
							>
								<div :class="[classes]">
									<input
										v-if="isSelectedSystemAdmin || !isCurrentSystemAdmin"
										id="admin-clinic"
										:value="clinicText"
										type="text"
										disabled
										class="form-control"
									/>

									<multiselect
										v-else
										id="admin-clinic"
										v-model.trim="adminForm.clinicId"
										track-by="id"
										label="name"
										:options="listClinics"
										:searchable="true"
										:show-labels="false"
										:allow-empty="true"
										:internal-search="false"
										@open="getClinics"
										@close="checkValidate('clinic')"
										@search-change="searchChange('clinic', $event)"
									>
										<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
										<template #noResult>{{ $t("DropDown.NoResult") }}</template>
									</multiselect>
									<div class="error-mess">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Admin's Permission -->
					<div class="form-group required">
						<div class="form-group__label col-form-label">
							<label for="admin-permission">{{ $t("AdminForm.Permission") }}</label>
							<a :href="permissionURL" target="_blank" class="link-permission link-redirect">{{
								$t("AdminForm.Reference")
							}}</a>
						</div>
						<div class="form-group__input"
							><ValidationProvider
								ref="permission"
								v-slot="{ classes, errors }"
								name="admin_permission"
								rules="required"
							>
								<div :class="[classes]">
									<multiselect
										id="admin-permission"
										v-model.trim="adminForm.role"
										:custom-label="labelRole"
										:options="roles"
										:searchable="false"
										:show-labels="false"
										:allow-empty="true"
										:disabled="isCurrentUser"
										@close="checkValidate('permission')"
									>
										<template #noOptions>{{ $t("DropDown.NoOptions") }}</template>
										<template #noResult>{{ $t("DropDown.NoResult") }}</template>
									</multiselect>
									<div class="error-mess text-left">{{ errors[0] }}</div>
								</div>
							</ValidationProvider>
						</div>
					</div>

					<!-- Update View -->
					<!-- Date created -->
					<div v-if="isUpdate" class="form-group">
						<label for="admin-date" class="form-group__label col-form-label">{{
							$t("AdminForm.DateCreated")
						}}</label>
						<div class="form-group__input col-form-label">{{ adminForm.createdAt }}</div>
					</div>

					<!-- Update View -->
					<!-- Creator -->
					<div v-if="isUpdate" class="form-group">
						<label for="admin-creator" class="form-group__label col-form-label">{{
							$t("AdminForm.Creator")
						}}</label>
						<div class="form-group__input col-form-label">{{ getCreatorName(adminForm) }}</div>
					</div>
				</div>

				<div class="admin-form__action text-center">
					<CButton v-bind="{ variant: 'outline' }" color="info" type="submit">{{
						textSubmit
					}}</CButton>
					<CButton
						v-if="isUpdate"
						v-bind="{ variant: 'outline' }"
						color="warning"
						@click="toggleDeleteModal(true)"
						>{{ $t("Button.Delete") }}</CButton
					>
					<CButton v-bind="{ variant: 'outline' }" color="danger" @click="cancelForm">{{
						$t("Button.Exit")
					}}</CButton>
				</div>
			</form>
		</ValidationObserver>

		<!-- Show modal when change admin status -->
		<CustomModal
			v-if="isShowStatusModal"
			:is-show-submit="false"
			:cancel-color="'outline-custom'"
			:cancel-text="$t('Button.OK')"
			@cancel-modal="confirmStatus"
		>
			<template #content>
				{{ $t("AdminForm.ConfirmStatus") }}
			</template>
		</CustomModal>

		<!-- Show modal when delete form -->
		<CustomModal
			v-if="isShowDeleteModal"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Close')"
			@submit-modal="deleteAdminById"
			@cancel-modal="toggleDeleteModal(false)"
		>
			<template #content>
				<p>{{ $t("AdminForm.AreYouSure") }}</p>
				<span>{{ $t("AdminForm.DeleteDescription") }}</span>
			</template>
		</CustomModal>

		<!-- Show modal when cancel if adminForm was changed -->
		<CustomModal
			v-if="isShowDiscardModal"
			:submit-text="$t('Button.Confirm')"
			:cancel-text="$t('Button.Close')"
			@submit-modal="discardChanges"
			@cancel-modal="toggleDiscardModal(false)"
		>
			<template #content>
				<p>{{ $t("Button.DiscardChanges") }}</p>
				<span>{{ $t("Button.DiscardContent") }}</span>
			</template>
		</CustomModal>

		<ChangeAdminPWD
			v-if="isShowPasswordModal"
			@submit-form="changePassword"
			@hide="togglePasswordModal(false)"
		/>
	</div>
</template>

<script>
import { USER_ROLE, ADMIN_STATUS } from "@/shared/plugins/constants"
import { GET_CLINIC } from "./../store/action-types"
import {
	CHANGE_STATUS_ADMIN_MODAL,
	CHANGE_STATUS_DISCARD_MODAL,
	CHANGE_STATUS_DELETE_MODAL,
	CHANGE_STATUS_PASSWORD_MODAL,
} from "./../store/mutation-types"
import { mapActions, mapMutations, mapState } from "vuex"
import { get, isEqual } from "lodash-es"

export default {
	name: "AdminForm",

	components: {
		ChangeAdminPWD: () => import("@/modules/admins/components/ChangeAdminPWD.vue"),
	},

	props: {
		isUpdate: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			originForm: {
				status: null,
				fullName: null,
				email: null,
				clinicId: null,
				role: null,
			},

			listClinics: [],

			adminForm: {},

			status: [ADMIN_STATUS.ACTIVE, ADMIN_STATUS.BANNED],

			roles: [USER_ROLE.SYSTEM_CLINIC, USER_ROLE.SUPPORT_CLINIC],

			permissionURL: process.env.VUE_APP_PERMISSION_URL,
			maxLength: process.env.VUE_APP_INPUT_MAX_LENGTH,
		}
	},

	computed: {
		...mapState("admins", [
			"recentAdmin",
			"clinics",
			"isShowStatusModal",
			"isShowDiscardModal",
			"isShowDeleteModal",
			"isShowPasswordModal",
		]),
		...mapState("authentication", ["currentUser"]),

		isSelectedSystemAdmin: function () {
			return this.adminForm.role === USER_ROLE.SYSTEM_ADMIN
		},

		isCurrentSystemAdmin: function () {
			return this.currentUser.role === USER_ROLE.SYSTEM_ADMIN
		},

		isCurrentUser: function () {
			return this.isUpdate && this.currentUser.email === this.adminForm.email
		},

		clinicText: function () {
			if (this.isSelectedSystemAdmin) return this.$t("AdminForm.All")
			else if (!this.isCurrentSystemAdmin) return this.currentUser?.clinic?.name

			return null
		},

		textSubmit: function () {
			return this.isUpdate ? this.$t("Button.SaveChanges") : this.$t("Button.CreateNew")
		},
	},

	watch: {
		recentAdmin: function () {
			this.setUpData()
		},

		"adminForm.role": function (val) {
			if (val === USER_ROLE.SYSTEM_ADMIN) this.adminForm.clinicId = null
		},

		clinics: function (val) {
			this.listClinics = [...val]
		},
	},

	created() {
		this.setUpData()
	},

	mounted() {
		this.setDataRoles()
		this.setInputLength()
	},

	beforeDestroy() {},

	methods: {
		get,

		...mapActions("admins", { GET_CLINIC }),
		...mapMutations("admins", {
			CHANGE_STATUS_ADMIN_MODAL,
			CHANGE_STATUS_DISCARD_MODAL,
			CHANGE_STATUS_DELETE_MODAL,
			CHANGE_STATUS_PASSWORD_MODAL,
		}),

		setUpData() {
			if (this.isUpdate) {
				this.adminForm = {
					...this.recentAdmin,
					name: this.recentAdmin?.fullName,
					clinicId: this.recentAdmin?.clinic,
				}
			} else this.adminForm = { ...this.originForm }
		},

		applyForm() {
			const params = {
				id: this.adminForm?.id,
				email: this.adminForm.email,
				fullName: this.adminForm.fullName,
				status: this.adminForm.status,
				role: this.adminForm.role,
				clinicId: this.adminForm.clinicId?.id,
				timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC",
			}
			if (this.isUpdate) params.isCurrentUser = this.isCurrentUser

			this.$emit("apply-form", params)
		},

		cancelForm() {
			let isEq
			if (this.isUpdate) {
				const dataForm = {}
				for (const key in this.recentAdmin) {
					dataForm[key] = this.adminForm[key]
				}
				dataForm.clinic = this.adminForm.clinicId

				isEq = isEqual(dataForm, this.recentAdmin)
			} else isEq = isEqual(this.adminForm, this.originForm)

			if (!isEq) this.toggleDiscardModal(true)
			else this.$emit("cancel-form")
		},

		discardChanges() {
			this.toggleDiscardModal(false)
			this.$emit("cancel-form")
		},

		toggleDiscardModal(status = false) {
			this.CHANGE_STATUS_DISCARD_MODAL(status)
		},

		deleteAdminById() {
			this.$emit("delete-form", this.adminForm)
			this.toggleDeleteModal(false)
		},

		toggleDeleteModal(status = false) {
			this.CHANGE_STATUS_DELETE_MODAL(status)
		},

		changePassword(params) {
			this.$emit("change-pwd", {
				...this.adminForm,
				newPassword: params,
			})
		},

		togglePasswordModal(status = false) {
			this.CHANGE_STATUS_PASSWORD_MODAL(status)
		},

		getClinics() {
			this.GET_CLINIC()
		},

		confirmStatus() {
			this.CHANGE_STATUS_ADMIN_MODAL(false)
		},

		selectStatus(val) {
			if (val === ADMIN_STATUS.BANNED) this.CHANGE_STATUS_ADMIN_MODAL(true)
		},

		setDataRoles() {
			if (this.currentUser?.role === USER_ROLE.SYSTEM_ADMIN)
				this.roles = [USER_ROLE.SYSTEM_ADMIN, ...this.roles]
			else this.adminForm.clinicId = this.currentUser?.clinic?.id
		},

		setInputLength() {
			document.querySelector("#admin-clinic").maxLength = this.maxLength
		},

		labelStatus(value) {
			return {
				[ADMIN_STATUS.ACTIVE]: this.$t("Status.Active"),
				[ADMIN_STATUS.BANNED]: this.$t("Status.Inactive"),
			}[value]
		},

		getCreatorName(admin) {
			if (!admin?.creator) return ""
			return `${this.labelRole(admin?.creator?.role)} ${admin?.creator?.fullName}`
		},

		labelRole(value) {
			return {
				[USER_ROLE.SYSTEM_ADMIN]: this.$t("AdminForm.SystemAdmin"),
				[USER_ROLE.SYSTEM_CLINIC]: this.$t("AdminForm.ClinicAdmin"),
				[USER_ROLE.SUPPORT_CLINIC]: this.$t("AdminForm.ClinicSupport"),
			}[value]
		},

		searchChange(field, value) {
			if (!value || !value.length) {
				this.listClinics = [...this.clinics]
				return
			}

			const valueSearch = this.nomalizeText(value)
			this.listClinics = this.clinics.filter(({ name }) => {
				return this.nomalizeText(name).includes(valueSearch)
			})

			if (this.$refs[field] && value.length) this.$refs[field].reset()
			else this.checkValidate(field)
		},

		checkValidate(field) {
			if (this.$refs[field]) this.$refs[field].validate()
		},
	},
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables";
@import "@/assets/scss/mixins";
.admin-form {
	&__header {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__container {
		display: grid;
		grid-gap: 10px 20px;
		width: 100%;
		margin-bottom: 15px;
		background-color: $color-white;
		border-radius: 12px;

		.logo {
			width: 24px;
			height: auto;
			&:hover {
				cursor: pointer;
			}
		}
		.logo + span {
			word-break: break-all;
			&:hover {
				cursor: pointer;
			}
		}
		.link-permission {
			text-decoration: underline;
			margin-left: 0.5rem;
		}
	}

	&__action {
		display: flex;
		justify-content: space-between;

		@media (min-width: $xxs) {
			justify-content: center;

			.btn + .btn {
				margin-left: 40px;
			}
		}
	}
}

input {
	height: 35px !important;
}

.form-group {
	display: flex;
	flex-wrap: wrap;
	gap: 2%;
	margin-bottom: 0;

	&[stretch] {
		.form-group__input {
			flex: 0 0 100%;
			max-width: 100%;

			@media (min-width: $xs) {
				flex: 0 0 75%;
				max-width: 75%;
			}
		}
	}

	&__label {
		flex: 0 0 100%;
		max-width: 100%;

		@media (min-width: $xs) {
			flex: 0 0 18%;
			max-width: 18%;
		}
	}

	&__input {
		flex: 0 0 100%;
		max-width: 100%;

		@media (min-width: $xs) {
			flex: 0 0 75%;
			max-width: 75%;
		}

		@media (min-width: $s) {
			flex: 0 0 50%;
			max-width: 50%;
		}

		@media (min-width: $ms) {
			flex: 0 0 30%;
			max-width: 30%;
		}
	}
}

.form-group.required label::after {
	content: "*";
	color: $color-red;
	margin-left: 2px;
}

.group-time {
	display: flex;
	flex-wrap: wrap;
	gap: 12px;
	margin-bottom: 10px;

	&__text {
		align-self: center;
	}

	&__input {
		width: 75px;
	}

	&__add {
		text-decoration: underline;
		align-self: center;
	}
}

.group-time + .error-mess {
	position: relative;
	top: -8px;
}

/deep/ .multiselect {
	font-size: 14px;
	&__single {
		font-size: 14px;
	}

	&__tag {
		margin-bottom: 0;
	}

	&__option {
		white-space: normal;
	}
}
</style>
